import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import NavLeft from './NavLeft';
import NavRight from './NavRight';

import { ConfigContext } from '../../../contexts/ConfigContext';
import logo from '../../../assets/images/cube_logoH.png';
import * as actionType from '../../../store/actions';
import useWindowSize from '../../../hooks/useWindowSize';

import { TitleReporte } from '../../../components/Title/Title';
import { CustomToast } from '../../../components/CustomNotification/CustomToast';

const NavBar = () => {
  const [moreToggle, setMoreToggle] = useState(false);
  const configContext = useContext(ConfigContext);
  const { collapseMenu, headerBackColor, headerFixedLayout, layout, subLayout } = configContext.state;
  const { dispatch } = configContext;
  const windowSize = useWindowSize();

  /*let showCuenta = localStorage.getItem('cuenta');
  
  let ruta = localStorage.getItem('ruta') || '';
  if(ruta == '/app/dashboard/promotoria' || ruta == '/app/dashboard/rutas'){showCuenta='Bepensa-Spirits'}*/
  // showCuenta=='' ? showCuenta="Bepensa" : showCuenta="Madrileña";

  let headerClass = ['navbar', 'pcoded-header', 'navbar-expand-lg', headerBackColor];
  if (headerFixedLayout && layout === 'vertical') {
    headerClass = [...headerClass, 'headerpos-fixed'];
  }

  let toggleClass = ['mobile-menu'];
  if (collapseMenu) {
    toggleClass = [...toggleClass, 'on'];
  }

  const navToggleHandler = () => {
    dispatch({ type: actionType.COLLAPSE_MENU });
  };


  //setInterval(notify, 8000);

  let moreClass = ['mob-toggler'];
  let collapseClass = ['collapse navbar-collapse'];
  if (moreToggle) {
    moreClass = [...moreClass, 'on'];
    collapseClass = [...collapseClass, 'd-block'];
  }

  /*const handlechange = (opt) => {
    let value = opt.value.value;
    // if (value.includes('epensa'))
    //   value = ''
    console.log('value: ', value);
    localStorage.setItem('cuenta', value)
    window.location.reload();
  }*/

  let navBar = (
    
    <React.Fragment>
      
      <div className="m-header">
        {windowSize.width < 992 &&
          <Link to="#" className={toggleClass.join(' ')} id="mobile-collapse" onClick={navToggleHandler}>
            <span />
          </Link>
        }
        <Link to="#" className="b-brand">
          <img id="main-logo" src={logo} alt="" className="logo" width={100} />
        </Link>

        <Link to="#" className={moreClass.join(' ')} onClick={() => setMoreToggle(!moreToggle)}>
          <i className="feather icon-more-vertical" />
        </Link>
      </div>

      <TitleReporte></TitleReporte>
      
      <div style={{ justifyContent: 'end' }} className={collapseClass.join(' ')}>
        <NavLeft />
        <NavRight />
      </div>
    </React.Fragment>
  );

  if (layout === 'horizontal' && subLayout === 'horizontal-2') {
    navBar = <div className="container">{navBar}</div>;
  }

  return (
    <React.Fragment>
      <header className={headerClass.join(' ')} style={{ zIndex: 1009 }}>
        {navBar}
      </header>
      <CustomToast></CustomToast>
    </React.Fragment>
  );
};

export default NavBar;
