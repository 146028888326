export const cadenasOptions = [
    { value: 'SAMS', label: 'SAMS', color: '#00B8D9' },
    { value: 'ARTELI', label: 'ARTELI', color: '#0052CC' },
    { value: 'BODEGA AURRERA TP', label: 'BODEGA AURRERA TP', color: '#5243AA' },
    { value: 'CHEDRAUI', label: 'CHEDRAUI', color: '#FF5630' },
    { value: 'CITY FRESKO', label: 'CITY FRESKO', color: '#FF8B00' },
    { value: 'GARIS', label: 'GARIS', color: '#FFC400' },
    { value: 'HEB', label: 'HEB', color: '#36B37E' },
    { value: 'OXXO', label: 'OXXO', color: '#00875A' },
    { value: 'SORIANA', label: 'SORIANA', color: '#253858' },
    { value: 'SUPERAMA TP', label: 'SUPERAMA TP', color: '#666666' },
    { value: 'SUPERCENTER TP', label: 'SUPERCENTER TP', color: '#00B8D9' },
];

export const middlewareApiServiceUrl = 'https://v2.retailanalyzer.com.mx:5000';

export const usersApiServiceUrl = 'https://v2.retailanalyzer.com.mx:5001';

export const repoApiServiceUrl = 'https://repo.retailanalyzer.com.mx';

export const getResponseData = (obj, single = false) => {
    let result;
    try {
        if (single)
            result = obj?.data?.data[0];
        else
            result = obj?.data?.data;
    } catch (error) {
        console.error('Cant obtain response data');
    }
    return result;
};

export const getPercentWeek = (num) => {
    let result = 0;
    try {
        result = (num / MAX_SEMANAS * 100).toFixed(2)
    } catch (error) {
        console.error(`Cant transform the num ${num} to percent`);
    }
    return result;
}

export const MAX_SEMANAS = 52;

export const fixNumericData = (data, aomuntFields = [], numericFields = []) => {
    let result = [];
    try {
        data.forEach(row => {
            aomuntFields.forEach(field => {
                row[field] = `$${parseInt(row[field].toFixed(0)).toLocaleString()}`;
            });
            numericFields.forEach(field => {
                row[field] = row[field].toLocaleString();
            });
            result.push(row);
        });
    } catch (error) {
        console.error(`Cant boild custom numeric data`);
        result = data;
    }
    return result;
}



export const COLOR_PALETTE = ['#8ECAE6', '#219EBC', '#023047', '#FFB703', '#FB8500'];
// export const COLOR_PALETTE = ['#0063a6', '#1f86aa', '#29abe2', '#91d6e8', '#d6d6d6', '#808080', '#808080'];

//export const REVERSE_COLOR_PALETTE = ['#FB8500', '#FFB703', '#023047', '#219EBC', '#8ECAE6','#370617','#00b4d8','#ffba08'];
//export const DEMO_COLOR_PALETTE = ['#F499FF', '#C88FBC', '#B771B9', '#9153A9', '#65448B', '#26284E'];
export const DEMO_COLOR_PALETTE = ['#fb8500', '#ffb703', '#023047', '#219ebc', '#8ecae6', '#ffd60a', '#ffc300', '#003566', '#34a0a4', '#52b69a'];
export const REVERSE_DEMO_COLOR_PALETTE = ['#fb8500', '#ffb703', '#023047', '#219ebc', '#8ecae6', '#ffd60a', '#ffc300', '#003566', '#34a0a4', '#52b69a'];
//export const REVERSE_DEMO_COLOR_PALETTE = ['#26284E', '#65448B', '#9153A9', '#B771B9', '#C88FBC', '#F499FF'];

// DEFAULT COLOR PALETTE
export const REVERSE_COLOR_PALETTE = ['#fb8500', '#ffb703', '#023047', '#219ebc', '#8ecae6', '#ffd60a', '#ffc300', '#003566', '#34a0a4', '#52b69a', '#fb8500', '#ffb703', '#023047', '#219ebc', '#8ecae6', '#ffd60a', '#ffc300', '#003566', '#34a0a4', '#52b69a'];
export const REVERSE_COLOR_PALETTE2 = ['#fb8500', '#ffb703', '#023047', '#219ebc', '#8ecae6', '#ffd60a', '#ffc300', '#003566', '#34a0a4', '#52b69a', '#fb8500', '#ffb703', '#023047', '#219ebc', '#8ecae6', '#ffd60a', '#ffc300', '#003566', '#34a0a4', '#52b69a', '#fb8500', '#ffb703', '#023047', '#219ebc', '#8ecae6', '#ffd60a', '#ffc300', '#003566', '#34a0a4', '#52b69a'];

export const REVERSE_COLOR_PALETTE_WHITE_SHADOW = ['#f7d3ab', '#f5cc64', '#99A2A6', '#84c2d1', '#c6e6f5', '#f7d3ab', '#f5cc64', '#99A2A6', '#84c2d1', '#c6e6f5', '#f7d3ab', '#f5cc64', '#99A2A6', '#84c2d1', '#c6e6f5', '#f7d3ab', '#f5cc64', '#99A2A6', '#84c2d1', '#c6e6f5', '#f7d3ab', '#f5cc64', '#99A2A6', '#84c2d1', '#c6e6f5', '#f7d3ab', '#f5cc64', '#99A2A6', '#84c2d1', '#c6e6f5', '#f7d3ab', '#f5cc64', '#99A2A6', '#84c2d1', '#c6e6f5', '#f7d3ab', '#f5cc64', '#99A2A6', '#84c2d1', '#c6e6f5', '#f7d3ab', '#f5cc64', '#99A2A6', '#84c2d1', '#c6e6f5', '#f7d3ab', '#f5cc64', '#99A2A6', '#84c2d1', '#c6e6f5'];
export const REVERSE_COLOR_PALETTE_WHITE_SHADOW2 = ['#FAE5D3', '#FCF3CF', '#CCD1D1', '#EAF2F8', '#EBF5FB', '#FAE5D3', '#FCF3CF', '#CCD1D1', '#EAF2F8', '#EBF5FB', '#FAE5D3', '#FCF3CF', '#CCD1D1', '#EAF2F8', '#EBF5FB','#FAE5D3', '#FCF3CF', '#CCD1D1', '#EAF2F8', '#EBF5FB', '#FAE5D3', '#FCF3CF', '#CCD1D1', '#EAF2F8', '#EBF5FB', '#FAE5D3', '#FCF3CF', '#CCD1D1', '#EAF2F8', '#EBF5FB', '#FAE5D3', '#FCF3CF', '#CCD1D1', '#EAF2F8', '#EBF5FB', '#FAE5D3', '#FCF3CF', '#CCD1D1', '#EAF2F8', '#EBF5FB', '#FAE5D3', '#FCF3CF', '#CCD1D1', '#EAF2F8', '#EBF5FB'];
// DEFAULT COLOR PALETTE

// BLUE COLOR PALETTE
// export const REVERSE_COLOR_PALETTE = [
//     '#0063a6', '#0081d9',
//     '#1f86aa', '#27a8d5',
//     '#29abe2', '#56bde8',
//     '#91d6e8', '#bbe6f1',
//     '#808080', '#9a9a9a',
//     '#c9c9c9', '#e3e3e3',];
// export const REVERSE_COLOR_PALETTE2 = [
//     '#0063a6', '#0081d9',
//     '#1f86aa', '#27a8d5',
//     '#29abe2', '#56bde8',
//     '#91d6e8', '#bbe6f1',
//     '#808080', '#9a9a9a',
//     '#c9c9c9', '#e3e3e3',
//     '#0063a6', '#0081d9',
//     '#1f86aa', '#27a8d5',
//     '#29abe2', '#56bde8',
//     '#91d6e8', '#bbe6f1',
//     '#808080', '#9a9a9a',
//     '#c9c9c9', '#e3e3e3',];

// export const REVERSE_COLOR_PALETTE_WHITE_SHADOW = [
//     '#5e89a5', '#73a8cc',
//     '#478ea8', '#60c0e3',
//     '#68c2e8', '#84cded',
//     '#b2e3f0', '#d0e8ed',
//     '#aeaeae', '#d0caca',
//     '#dbd9d9', '#e3e3e3'];
// export const REVERSE_COLOR_PALETTE_WHITE_SHADOW2 = [
//     '#5e89a5', '#73a8cc',
//     '#478ea8', '#60c0e3',
//     '#68c2e8', '#84cded',
//     '#b2e3f0', '#d0e8ed',
//     '#aeaeae', '#d0caca',
//     '#dbd9d9', '#e3e3e3',
//     '#5e89a5', '#73a8cc',
//     '#478ea8', '#60c0e3',
//     '#68c2e8', '#84cded',
//     '#b2e3f0', '#d0e8ed',
//     '#aeaeae', '#d0caca',
//     '#dbd9d9', '#e3e3e3'];
// BLUE COLOR PALETTE

// V2 COLOR PALETTE
// export const REVERSE_COLOR_PALETTE = [
//     '#225894',
//     '#44a2e8',
//     '#5d9732',
//     '#a2cd40',
//     '#f6c343',
//     '#fb8500',
//     '#af0b0b',];
// export const REVERSE_COLOR_PALETTE2 = [
//     '#225894',
//     '#44a2e8',
//     '#5d9732',
//     '#a2cd40',
//     '#f6c343',
//     '#fb8500',
//     '#af0b0b',
//     '#225894',
//     '#44a2e8',
//     '#5d9732',
//     '#a2cd40',
//     '#f6c343',
//     '#fb8500',
//     '#af0b0b',];

// export const REVERSE_COLOR_PALETTE_WHITE_SHADOW = [
//     '#526f91',
//     '#79b7e7',
//     '#789264',
//     '#bfd58d',
//     '#fae2a4',
//     '#eed2b2',
//     '#a87171',];
// export const REVERSE_COLOR_PALETTE_WHITE_SHADOW2 = [
//     '#526f91',
//     '#79b7e7',
//     '#789264',
//     '#bfd58d',
//     '#fae2a4',
//     '#eed2b2',
//     '#a87171',
//     '#526f91',
//     '#79b7e7',
//     '#789264',
//     '#bfd58d',
//     '#fae2a4',
//     '#eed2b2',
//     '#a87171',];
// V2 COLOR PALETTE

export const NOT_ASSIGNED_LABEL = 'SIN ASIGNAR';

export const NO_OPTIONS_LABEL = 'Sin opciones';

export const SELECT_LABEL = 'Selecciona...';

export const OPEN_ICON = '<?xml version="1.0" encoding="UTF-8"?><svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" fill="none" stroke-width="1.5" viewBox="0 0 24 24" color="#6e8192"><path stroke="#6e8192" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" d="m20 20-5-5m0 0v4m0-4h4M4 20l5-5m0 0v4m0-4H5M20 4l-5 5m0 0V5m0 4h4M4 4l5 5m0 0V5m0 4H5"></path></svg>';
export const CLOSE_ICON = '<?xml version="1.0" encoding="UTF-8"?><svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" fill="none" stroke-width="1.5" viewBox="0 0 24 24" color="#6e8192"><path stroke="#6e8192" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" d="M7 4H4v3M17 4h3v3M7 20H4v-3M17 20h3v-3"></path></svg>';
export const HORIZONTAL_EXPAND_ICON = `<?xml version="1.0" encoding="utf-8"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools --> <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M15 17L20 12L15 7M9 7L4 12L9 17" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>`;
export const HORIZONTAL_CONTRACT_ICON = `<?xml version="1.0" encoding="utf-8"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools --> <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M15 17L20 12L15 7M9 7L4 12L9 17" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>`;


export const EXTERNALS_USERS_TO_NOTIFY = ['enrosalese@bepensa.com', 'fernando.acosta@madrilena.com.mx'];

export const validateOnProcessDateRange = (date, initRange, endRange) => {
    let isValid = false;
    try {
        date = parseInt(`${date}`);
        initRange = parseInt(`${initRange}`);
        endRange = parseInt(`${endRange}`);
        isValid = !(date >= initRange && date <= endRange);
    } catch (error) {
        console.error(`Cant validate date`);
    }
    return isValid;

}

export const DEFAULT_LAYOUT_HEADER = {
    "idProyectoCadena": "",
    "Cadena": "",
    "Razón Social": "",
    "Número Tienda Real": "",
    "Nombre Tienda": "",
    "UPC": "",
    "Código Interno Cliente": "",
    "Ítem": "",
    "Nombre Producto": "",
    "Venta Unidades": "",
    "Venta Pesos": "",
    "Inventario Unidades": "",
    "Inventario Pesos": "",
    "Inventario Tránsito  Unidades": "",
    "Inventario Tránsito  Pesos": "",
    "Inventario Warehouse Unidades": "",
    "Inventario Warehouse Pesos": "",
    "Inventario on order Unidades": "",
    "Inventario on order Pesos": "",
    "fecha": ""
};

export const DEFAULT_MANDATORY_LABEL = 'OBLIGATORIO';

export const DEFAULT_PREFERENCY_LABEL = 'PREFERENTE';

export const setMandatoryLayoutFields = (client, isLm = false) => {
    let result = {};
    try {
        result = {
            ...client,
            "UPC": "",
            "Código Interno Cliente": "",
            "Ítem": "",
            "idProyectoCadena": DEFAULT_MANDATORY_LABEL,
            "Número Tienda Real": DEFAULT_MANDATORY_LABEL,
            "Nombre Tienda": DEFAULT_MANDATORY_LABEL,
            "Nombre Producto": DEFAULT_MANDATORY_LABEL,
            "fecha": DEFAULT_MANDATORY_LABEL
        };
        const cadena = client.Cadena.toLowerCase();
        switch (cadena) {
            case 'grupo rivera':
                result["Ítem"] = DEFAULT_MANDATORY_LABEL;
                break;
            case '7eleven':
                result["Código Interno Cliente"] = DEFAULT_MANDATORY_LABEL;
                break;
            case 'bodegas alianza':
                result["UPC"] = DEFAULT_MANDATORY_LABEL;
                result["Código Interno Cliente"] = DEFAULT_MANDATORY_LABEL;
                break;
            case 'calimax':
                result["UPC"] = DEFAULT_MANDATORY_LABEL;
                result["Código Interno Cliente"] = DEFAULT_MANDATORY_LABEL;
                break;
            case 'casa ley':
                result["UPC"] = DEFAULT_MANDATORY_LABEL;
                break;
            case 'chedraui':
                result["UPC"] = `${DEFAULT_PREFERENCY_LABEL}, en caso de no contar con el valor de "Código Interno Cliente", de lo contrario debe ir vacío`;
                result["Código Interno Cliente"] = `${DEFAULT_PREFERENCY_LABEL}, en caso de no contar con el valor de "UPC", de lo contrario debe ir vacío`;
                break;
            case 'city fresko':
                result["UPC"] = DEFAULT_MANDATORY_LABEL;
                break;
            case 'garis':
                result["UPC"] = DEFAULT_MANDATORY_LABEL;
                break;
            case 'heb':
                result["UPC"] = DEFAULT_MANDATORY_LABEL;
                result["Código Interno Cliente"] = DEFAULT_MANDATORY_LABEL;
                break;
            case 'oxxo':
                result["UPC"] = DEFAULT_MANDATORY_LABEL;
                result["Ítem"] = DEFAULT_MANDATORY_LABEL;
                break;
            case 'sams':
                result["Código Interno Cliente"] = DEFAULT_MANDATORY_LABEL;
                break;
            case 'soriana':
                result["Código Interno Cliente"] = DEFAULT_MANDATORY_LABEL;
                break;
            case 'futurama':
                result["UPC"] = DEFAULT_MANDATORY_LABEL;
                result["Código Interno Cliente"] = DEFAULT_MANDATORY_LABEL;
                break;
            case 'liverpool':
                result["UPC"] = DEFAULT_MANDATORY_LABEL;
                result["Código Interno Cliente"] = DEFAULT_MANDATORY_LABEL;
                result["Ítem"] = DEFAULT_MANDATORY_LABEL;
                break;
            case 'palacio de hierro':
                result["UPC"] = DEFAULT_MANDATORY_LABEL;
                result["Código Interno Cliente"] = DEFAULT_PREFERENCY_LABEL;
                result["Ítem"] = DEFAULT_MANDATORY_LABEL;
                break;
            case 'la europea':
                result["Código Interno Cliente"] = DEFAULT_MANDATORY_LABEL;
                break;
            case 'supercenter tp':
                result["UPC"] = DEFAULT_MANDATORY_LABEL;
                result["Código Interno Cliente"] = DEFAULT_MANDATORY_LABEL;
                result["Ítem"] = DEFAULT_MANDATORY_LABEL;
                break;
            case 'bodega aurrera tp':
                result["UPC"] = DEFAULT_MANDATORY_LABEL;
                result["Código Interno Cliente"] = DEFAULT_MANDATORY_LABEL;
                result["Ítem"] = DEFAULT_MANDATORY_LABEL;
                break;
            case 'superama tp':
                result["UPC"] = DEFAULT_MANDATORY_LABEL;
                result["Código Interno Cliente"] = DEFAULT_MANDATORY_LABEL;
                result["Ítem"] = DEFAULT_MANDATORY_LABEL;
                break;
            case 'casa vargas':
                result["Código Interno Cliente"] = DEFAULT_MANDATORY_LABEL;
                break;
            case 'grupo duero':
                result["Código Interno Cliente"] = DEFAULT_MANDATORY_LABEL;
                break;
            case 'san francisco de asis':
            case 'super aki':
                result["UPC"] = DEFAULT_MANDATORY_LABEL;
                if (isLm)
                    result["Código Interno Cliente"] = DEFAULT_MANDATORY_LABEL;
                break;
            case 'productos de consumo z':
                result["Código Interno Cliente"] = DEFAULT_MANDATORY_LABEL;
                result["Ítem"] = DEFAULT_MANDATORY_LABEL;
                break;
            case 'costco':
                result["Código Interno Cliente"] = DEFAULT_MANDATORY_LABEL;
                break;
        }
    } catch (error) {
        console.error(`Cant map mandatory fields to ${client.Cadena}`);
        console.error(error);
        result = client;
    }
    return result;
}