import React from 'react';
import AnimatedModal from '../../components/Modal/AnimatedModal';

const Spinner = () => {

  return (
    <React.Fragment>
      <AnimatedModal
        visible={true}
        animation={'fade'}
        width={70}
        modalStyles={{ background: 'none', boxShadow: 'none' }}
      >
        <div className="d-flex justify-content-center">
          <div className="spinner-border" role="status" />
        </div>
      </AnimatedModal>
    </React.Fragment>
  );
};

export default Spinner;
