import React, { useContext, useEffect, useRef, useState } from 'react';

import Navigation from './Navigation';
import NavBar from './NavBar';
import Breadcrumb from './Breadcrumb';
import Configuration from './Configuration';
// import { Outlet } from 'react-router-dom';

import useWindowSize from '../../hooks/useWindowSize';
import useOutsideClick from '../../hooks/useOutsideClick';
import { ConfigContext } from '../../contexts/ConfigContext';
import * as actionType from '../../store/actions';

const AdminLayout = ({ children }) => {
  const windowSize = useWindowSize();
  const ref = useRef();
  const configContext = useContext(ConfigContext);

  const { collapseMenu, layout, subLayout, headerFixedLayout } = configContext.state;
  const { dispatch } = configContext;

  // const [version, setVersion] = useState('4.7.24');
  // const [versionLocal, setVersionLocal] = useState(localStorage.getItem('versionLocal') || '');

  // useEffect(() => {
  //   console.log('versionLocal: ', versionLocal);
  //   console.log('version: ', version);
  //   if (versionLocal !== version) {
  //     alert(`Se encontró una nueva versión (${version}), se actualizará en este momento`);
  //   }
  // }, [children]);

  useOutsideClick(ref, () => {
    if (collapseMenu) {
      dispatch({ type: actionType.COLLAPSE_MENU });
    }
  });

  useEffect(() => {
    if (windowSize.width > 992 && windowSize.width <= 1024 && layout !== 'horizontal') {
      dispatch({ type: actionType.COLLAPSE_MENU });
    }

    if (layout === 'horizontal' && windowSize.width < 992) {
      dispatch({ type: actionType.CHANGE_LAYOUT, layout: 'vertical' });
    }
  }, [dispatch, layout, windowSize]);

  const mobileOutClickHandler = () => {
    if (windowSize.width < 992 && collapseMenu) {
      dispatch({ type: actionType.COLLAPSE_MENU });
    }
  };

  let mainClass = ['pcoded-wrapper'];
  if (layout === 'horizontal' && subLayout === 'horizontal-2') {
    mainClass = [...mainClass, 'container'];
  }

  let common = (
    <React.Fragment>
      <Navigation />
      <NavBar />
    </React.Fragment>
  );

  if (windowSize.width < 992) {
    let outSideClass = ['nav-outside'];
    if (collapseMenu) {
      outSideClass = [...outSideClass, 'mob-backdrop'];
    }
    if (headerFixedLayout) {
      outSideClass = [...outSideClass, 'mob-fixed'];
    }

    common = (
      <div className={outSideClass.join(' ')} ref={ref}>
        {common}
      </div>
    );
  }
  const [isValid, setIsValid] = useState(localStorage.getItem('serviceToken'));
  useEffect(() => {
    if (!isValid)
      window.location = '/auth/signin';
  }, [isValid]);
  return !isValid ? <></> : (
    <React.Fragment>
      {common}
      <div className="pcoded-main-container" onClick={() => mobileOutClickHandler}>
        <div className={mainClass.join(' ')}>
          {/* se oculto esta propiedad  <div className="pcoded-content">  */}
          <div className="">
            <div className="pcoded-inner-content">
              <Breadcrumb />
              {children}
              {/* V {version} */}
            </div>
          </div>
        </div>
      </div>
      <Configuration />
    </React.Fragment>
  );
};

export default AdminLayout;
